<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          class="pa-4 ma-0"
        >
          <h2>Bienvenue, {{ user.firstName }}</h2>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          class="px-8 py-4 ma-0"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <h3>Formations confirmées</h3>
            </v-col>
            <v-col
              cols="12"
            >
            <v-data-table
              class="table-formations"
              :headers="headersFormationsConfirmees"
              :items="formationsConfirmees"
              :items-per-page="5"
              :sort-by="'dateDebut'"
              :sort-desc="false"
              @click:row="rowClickFormation"
            >
              <template v-slot:[`item.intitule`]="{ item }">
                {{ item.intitule }}
                <v-tooltip
                  bottom
                  v-if="item.etat === 'ENCOURS'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-1 blinking"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-radiobox-marked
                    </v-icon>
                  </template>
                  <span>En cours</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-if="item.etat === 'CONFIRME'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-1"
                      color="green"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-check-circle
                    </v-icon>
                  </template>
                  <span>Confirmée</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.formateurs`]="{ item }">
                <span v-if="item.formateurs.length > 0">
                  {{ item.formateurs.filter(f => f).map(f => f.prenom.charAt(0) + '. ' + f.nom).join(', ') }}
                </span>
                <span v-else>
                  <i>Aucun</i>
                </span>
              </template>
              <template v-slot:[`item.adresse`]="{ item }">
                {{ item.adresse.ville }} {{ item.adresse.departement ? '(' + item.adresse.departement + ')' : '' }}
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <div v-html="displayType(item.type)"></div>
              </template>
            </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          class="px-8 py-4 ma-0"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <h3>Données financières</h3>
            </v-col>
            <v-col
              cols="12"
            >
              <chartist
                :data="chiffreAffairesChart.data"
                :options="chiffreAffairesChart.options"
                type="Line"
                style="max-height: 400px;"
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-card
                    class="pa-2 my-2"
                    color="#9d8be4"
                    dark
                  >
                    <v-card-title>
                      <v-checkbox
                        label="CA prévisionnel"
                        v-model="selectedCAPrevisionnel"
                        @change="updateChartCA()"
                      />
                    </v-card-title>
                    <div class="px-4">
                      <p class="mb-1">
                        Total annuel : {{ toEuro(caPrevisionnelAnnuel) }}
                      </p>
                      <p>
                        Moyenne mensuelle : {{ toEuro(caPrevisionnelAnnuel / serieCAPrevisionnel.length) }}
                      </p>
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-card
                    class="pa-2 my-2"
                    color="primary"
                    dark
                  >
                    <v-card-title>
                      <v-checkbox
                        label="CA facturé"
                        v-model="selectedCAFacture"
                        @change="updateChartCA()"
                      />
                    </v-card-title>
                    <div class="px-4">
                      <p class="mb-1">
                        Total annuel : {{ toEuro(caFactureAnnuel) }}
                      </p>
                      <p>
                        Moyenne mensuelle : {{ toEuro(caFactureAnnuel / serieCAFacture.length) }}
                      </p>
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-card
                    class="pa-2 my-2"
                    color="#2e1f69"
                    dark
                  >
                    <v-card-title>
                      <v-checkbox
                        label="CA encaissé"
                        v-model="selectedCAEncaisse"
                        @change="updateChartCA()"
                      />
                    </v-card-title>
                    <div class="px-4">
                      <p class="mb-1">
                        Total annuel : {{ toEuro(caEncaisseAnnuel) }}
                      </p>
                      <p>
                        Moyenne mensuelle : {{ toEuro(caEncaisseAnnuel / serieCAEncaisse.length ) }}
                      </p>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!--
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="pa-4 ma-0"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <h3>Répartition par client</h3>
            </v-col>
            <v-col
              cols="12"
            >
              <chartist
                :data="repartitionCAClientsChart.data"
                :options="repartitionCAClientsChart.options"
                type="Pie"
                style="max-height: 300px;"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <base-material-chart-card
          :data="joursFormationChart.data"
          :options="joursFormationChart.options"
          :responsive-options="joursFormationChart.responsiveOptions"
          color="#E91E63"
          hover-reveal
          type="Bar"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Jours de formation
          </h4>
        </base-material-chart-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-human-male-board"
          title="Formations"
          :value="countFormationsThisYear"
          sub-icon="mdi-clock"
          sub-text="Cette année"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="primary"
          icon="mdi-poll"
          title="Jours de formation"
          :value="daysThisYear"
          sub-icon="mdi-tag"
          sub-text="Cette année"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="success"
          icon="mdi-cash-multiple"
          title="Chiffre d'affaires"
          :value="caThisYear + ' €'"
          sub-icon="mdi-calendar"
          sub-text="Cette année"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="orange"
          icon="mdi-account-school"
          title="Participants"
          :value="participantsThisYear"
          sub-icon="mdi-alert"
          sub-text="Cette année"
        />
      </v-col>
    -->

      <!--
      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Employees Stats
            </div>

            <div class="subtitle-1 font-weight-light">
              New employees on 15th September, 2016
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="tabs"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="subheading font-weight-light mx-3"
                style="align-self: center"
              >Tasks:</span>
              <v-tab class="mr-3">
                <v-icon class="mr-2">
                  mdi-bug
                </v-icon>
                Bugs
              </v-tab>
              <v-tab class="mr-3">
                <v-icon class="mr-2">
                  mdi-code-tags
                </v-icon>
                Website
              </v-tab>
              <v-tab>
                <v-icon class="mr-2">
                  mdi-cloud
                </v-icon>
                Server
              </v-tab>
            </v-tabs>
          </template>

          <v-tabs-items
            v-model="tabs"
            class="transparent"
          >
            <v-tab-item
              v-for="n in 3"
              :key="n"
            >
              <v-card-text>
                <template v-for="(task, i) in tasks[tabs]">
                  <v-row
                    :key="i"
                    align="center"
                  >
                    <v-col cols="1">
                      <v-list-item-action>
                        <v-checkbox
                          v-model="task.value"
                          color="secondary"
                        />
                      </v-list-item-action>
                    </v-col>

                    <v-col cols="9">
                      <div
                        class="font-weight-light"
                        v-text="task.text"
                      />
                    </v-col>

                    <v-col
                      cols="2"
                      class="text-right"
                    >
                      <v-icon class="mx-1">
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        color="error"
                        class="mx-1"
                      >
                        mdi-close
                      </v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
      </v-col>
    </v-row>
    -->
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        user: JSON.parse(localStorage.getItem('mcdm-user')),
        serieCAPrevisionnel: [],
        serieCAFacture: [],
        serieCAEncaisse: [],
        seriesCA: [this.serieCAPrevisionnel, this.serieCAFacture, this.serieCAEncaisse],
        selectedCAPrevisionnel: true,
        selectedCAFacture: true,
        selectedCAEncaisse: true,
        caFactureAnnuel: 0,
        caEncaisseAnnuel: 0,
        caPrevisionnelAnnuel: 0,
        chiffreAffairesChart: {
          data: {
            labels: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
            series: [],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.simple({
              divisor: 3,
            }),
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: -50,
              bottom: 0,
              left: 20,
            },
          },
        },
        repartitionCAClientsChart: {
          data: {
            labels: [],
            series: [],
          },
          options: {},
        },
        joursFormationChart: {
          data: {
            labels: ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
            series: [
              [],
            ],
          },
          options: {
            axisX: {
              showGrid: true,
            },
            low: 0,
            high: 0,
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        headersFormationsConfirmees: [
          {
            sortable: true,
            text: 'Intitulé',
            value: 'intitule',
          },
          {
            sortable: false,
            text: 'Réf.',
            value: 'reference',
          },
          {
            sortable: false,
            text: 'Type',
            value: 'type',
          },
          {
            sortable: true,
            text: 'Date début',
            value: 'dateDebutFormatted',
          },
          {
            sortable: true,
            text: 'Date fin',
            value: 'dateFinFormatted',
          },
          {
            sortable: true,
            text: 'Formateur(s)',
            value: 'formateurs',
          },
          {
            sortable: false,
            text: 'Lieu',
            value: 'adresse',
          },
          {
            sortable: true,
            text: 'Stagiaires',
            value: 'nbStagiaires',
          },
        ],
        tabs: 0,
        formationsConfirmees: [],
        countFormationsThisYear: '',
        daysThisYear: '',
        participantsThisYear: '',
        caThisYear: 0,
        factures: [],
      }
    },

    created() {
      this.getFormationsEnCoursAndConfirmees();

      const annee = parseInt(new Date().getFullYear());

      this.axios.get('/formations/CAPrevisionnelMensuel/' + annee)
        .then((res) => {
          this.serieCAPrevisionnel = [...res.data];
          this.caPrevisionnelAnnuel = res.data.reduce((a, b) => a + b, 0); // somme des éléments de l'array
          if (Math.max(...this.serieCAPrevisionnel) > this.chiffreAffairesChart.options.high) {
            this.chiffreAffairesChart.options.high = Math.max(...this.serieCAPrevisionnel) + 500;
          }
          return this.axios.get('/facturesSortantes/CAFactureMensuel/' + annee);
        })
        .then((res) => {
          this.serieCAFacture = [...res.data];
          this.caFactureAnnuel = res.data.reduce((a, b) => a + b, 0);
          if (Math.max(...this.serieCAFacture) > this.chiffreAffairesChart.options.high) {
            this.chiffreAffairesChart.options.high = Math.max(...this.serieCAFacture) + 500;
          }
          return this.axios.get('/facturesSortantes/CAEncaisseMensuel/' + annee);
        })
        .then((res) => {
          this.serieCAEncaisse = [...res.data];
          this.caEncaisseAnnuel = res.data.reduce((a, b) => a + b, 0);
          if (Math.max(...this.serieCAEncaisse) > this.chiffreAffairesChart.options.high) {
            this.chiffreAffairesChart.options.high = Math.max(...this.serieCAEncaisse) + 500;
          }
          this.chiffreAffairesChart.data.series = [this.serieCAPrevisionnel, this.serieCAFacture, this.serieCAEncaisse];
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du chiffre d\'affaires', type: 'warn' });
        });

      /*
      this.axios.get('/facturesSortantes/annee/' + annee)
        .then((res) => {
          this.factures = [...res.data];
          // this.calculCAMensuel();
          // this.calculCAAnnuel();
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des factures', type: 'warn' });
        });
      */
      /*
      this.axios.get('/formations/terminees/annee/' + annee)
        .then((res) => {
          this.formationsThisYear = [...res.data];
          this.calculJoursFormationMensuel();
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des formations terminées', type: 'warn' });
        });
      */
      this.axios.get('/formations/terminees/annee/' + annee + '/count')
        .then((res) => {
          this.countFormationsThisYear = String(res.data);
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des formations', type: 'warn' });
        });
      this.axios.get('/formations/joursFormes/annee/' + annee)
        .then((res) => {
          this.daysThisYear = String(res.data);
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du nombre de jours formations', type: 'warn' });
        });
      this.axios.get('/formations/stagiaires/annee/' + annee + '/count')
        .then((res) => {
          this.participantsThisYear = String(res.data);
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du nombre de participants formés', type: 'warn' });
        });
    },

    methods: {
      getFormationsEnCoursAndConfirmees() {
        this.axios.get('/formations/enCours')
          .then((res) => {
            this.formationsConfirmees = [...res.data];
            this.formationsConfirmees.forEach(formation => {
              formation.dateDebutFormatted = new Date(formation.dateDebut).toLocaleString('fr-FR').substring(0, 10);
              formation.dateFinFormatted = new Date(formation.dateFin).toLocaleString('fr-FR').substring(0, 10);
              if (formation.distanciel) {
                formation.adresse = { ville: 'Visio' };
              }
            });
            return this.axios.get('/formations/confirmees')
          })
          .then((res) => {
            this.formationsConfirmees = this.formationsConfirmees.concat([...res.data]);
            this.formationsConfirmees.forEach(formation => {
              formation.dateDebutFormatted = new Date(formation.dateDebut).toLocaleString('fr-FR').substring(0, 10);
              formation.dateFinFormatted = new Date(formation.dateFin).toLocaleString('fr-FR').substring(0, 10);
              if (formation.distanciel) {
                formation.adresse = { ville: 'Visio' };
              }
            });
            return res;
          });
      },

      rowClickFormation(item, row) {
        this.$router.push({ name: 'FormationDetail', params: { id: item.id } });
      },

      displayType(type) {
        switch (type) {
          case 'INTER':
            return '<span class="badge-chips-small badge-blue-outline">Inter</span>';
          case 'INTRA':
            return '<span class="badge-chips-small badge-yellow-outline">Intra</span>';
          case 'SSTRT':
            return '<span class="badge-chips-small badge-gray-outline">Sous-traitée</span>';
        }
      },

      updateChartCA() {
        if (this.selectedCAPrevisionnel) {
          this.chiffreAffairesChart.data.series[0] = this.serieCAPrevisionnel;
        } else {
          this.chiffreAffairesChart.data.series[0] = [];
        }

        if (this.selectedCAFacture) {
          this.chiffreAffairesChart.data.series[1] = this.serieCAFacture;
        } else {
          this.chiffreAffairesChart.data.series[1] = [];
        }

        if (this.selectedCAEncaisse) {
          this.chiffreAffairesChart.data.series[2] = this.serieCAEncaisse;
        } else {
          this.chiffreAffairesChart.data.series[2] = [];
        }

        // Forcer la MAJ
        this.chiffreAffairesChart.data.series = [...this.chiffreAffairesChart.data.series];
      },

      complete (index) {
        this.list[index] = !this.list[index]
      },

      /*
      calculCAMensuel() {
        let totalHT;
        let facturesThisMonth;
        for (let i = 1; i <= new Date().getMonth() + 1; i++) {
          totalHT = 0;
          facturesThisMonth = this.factures.filter((f) => new Date(f.dateEmission).getMonth() + 1 === i);
          facturesThisMonth.forEach(facture => {
            for (const key in facture) {
              if (key === 'montantHT') {
                totalHT += parseInt(facture[key]);
              }
            }
          });

          this.chiffreAffairesChart.data.series[0].push(totalHT);
        }
      },

      calculCAAnnuel() {
        this.factures.forEach(facture => {
          for (const key in facture) {
            if (key === 'montantHT') {
              this.caThisYear += parseInt(facture[key]);
            }
          }
        });
      },
      */
      calculJoursFormationMensuel() {
        let daysThisMonth;
        let formationsThisMonth;
        for (let i = 1; i <= new Date().getMonth() + 1; i++) {
          daysThisMonth = 0;
          formationsThisMonth = this.formationsThisYear.filter((f) => new Date(f.dateFin).getMonth() + 1 === i);
          formationsThisMonth.forEach(formation => {
            for (const key in formation) {
              if (key === 'dureeJours') {
                daysThisMonth += parseInt(formation[key]);
              }
            }
          });

          this.joursFormationChart.data.series[0].push(daysThisMonth);

          if (daysThisMonth > this.joursFormationChart.options.high) {
            this.joursFormationChart.options.high = daysThisMonth + 1;
          }
        }
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(num);
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }
</script>

<style scoped>
  /* Style du CA prévisionnel */
  #dashboard >>> .ct-series-a .ct-line, #dashboard >>> .ct-series-a .ct-point {
    stroke: #9d8be4;
  }
  /* Style du CA facturé */
  #dashboard >>> .ct-series-b .ct-line, #dashboard >>> .ct-series-b .ct-point {
    stroke: #5d3fd3;
  }
  /* Style du CA encaissé */
  #dashboard >>> .ct-series-c .ct-line, #dashboard >>> .ct-series-c .ct-point {
    stroke: #2e1f69;
  }

  .table-formations >>> tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  .table-formations >>> .badge-chips-small {
    white-space: nowrap;
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 0.7rem;
    font-weight: 700;
  }

  .table-formations >>> .badge-blue-outline {
    border: solid 1px #4b70ff;
    color: #4b70ff;
  }

  .table-formations >>> .badge-yellow-outline {
    border: solid 1px #c98d17;
    color: #c98d17;
  }

  .table-formations >>> .badge-gray-outline {
    border: solid 1px #797b86;
    color: #797b86;
  }

</style>
